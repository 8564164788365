import React from "react";

const Waves = () => {
  return (
    <div>
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 30 160 30"
        preserveAspectRatio="none"
        shape-rendering="auto">
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#5ba0fa" stopOpacity="0%" />
            <stop offset="80%" stopColor="#034191" stopOpacity="100%" />
          </linearGradient>
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(188, 229, 238, 0.4)" />
          <use xlinkHref="#gentle-wave" x="48" y="10" fill="rgba(135, 223, 255, 0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="13" fill="url(#grad1)" />
          <use xlinkHref="#gentle-wave" x="48" y="16" fill="#2d61a5" />
        </g>
      </svg>
    </div>
  );
};
export default Waves;
