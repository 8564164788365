import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCyYkmllQ4WNUmU4rxJTMjyhvFA3IqEcC4",
  authDomain: "clock-availability.firebaseapp.com",
  databaseURL: "https://clock-availability.firebaseio.com",
  projectId: "clock-availability",
  storageBucket: "clock-availability.appspot.com",
  messagingSenderId: "659005322925",
  appId: "1:659005322925:web:8e8926f331f8a1c9fcf824",
  measurementId: "G-B6L2LEP1J8",
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const writeUserTimes = async (uid, data) => {
  const result = await firestore.doc(`users/${uid}`).update({
    times: data,
  });
};

export const getOtherUser = async (uid) => {
  const result = await firestore.doc(`users/${uid}`).get();
  return { uid, ...result.data() };
};

export const generateUserDocument = async (user, additionalData?) => {
  const getUserDocument = async (uid: string) => {
    if (!uid) return null;
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get();

      return {
        uid,
        ...userDocument.data(),
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { email, displayName } = user;
    const arr: boolean[] = [];
    for (var i = 0; i < 5 * 24; i++) {
      arr.push(false);
    }
    try {
      await userRef.set({
        displayName,
        email,
        times: arr,
        timeZone: "Europe/London",
        ...additionalData,
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }

  return getUserDocument(user.uid);
};
