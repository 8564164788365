import React, { useContext } from "react";
import { Router } from "@reach/router";
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import Home from "./Home";
import PasswordForgot from "./PasswordForgot";
import { UserContext } from "../providers/UserProvider";
import "./styles.css";

const App = () => {
  const { user, otherUser } = useContext(UserContext) || {};

  return user && otherUser ? (
    <Home />
  ) : (
    <Router>
      {/* <SignUp path="signUp" /> */}
      <LogIn path="/" />
      <PasswordForgot path="passwordForgot" />
    </Router>
  );
};

export default App;
