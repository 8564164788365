"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});
var light = (exports.light = {
  background: "#fff",
  border: "#ececec",
  center: "#000",
  seconds: "#f56c6c",
  minutes: "#ccc",
  hour: "#000",
  tick: "#000",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 8,
});

var dark = (exports.dark = {
  background: "#000",
  border: "#000",
  center: "#fff",
  seconds: "#fff",
  minutes: "#ccc",
  hour: "#fff",
  tick: "#fff",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 8,
});

var aqua = (exports.aqua = {
  background: "#eaeaea",
  border: "#3dd4c1",
  center: "#000",
  seconds: "#f56c6c",
  minutes: "#9c9c9c",
  hour: "#000",
  tick: "#000",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 10,
});

var lime = (exports.lime = {
  background: "#a4f181",
  border: "#fff",
  center: "#ccc",
  seconds: "#fff",
  minutes: "#ccc",
  hour: "#fff",
  tick: "#fff",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 8,
});

var sherbert = (exports.sherbert = {
  background: "linear-gradient(to left, #fee , #ddefbb)",
  border: "#fff",
  center: "#fff",
  seconds: "#fff",
  minutes: "#ccc",
  hour: "#fff",
  tick: "#fff",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 8,
});

var navy = (exports.navy = {
  background: "linear-gradient(#2a70a0,#102d42)",
  border: "#fff",
  center: "#fff",
  seconds: "#fff",
  minutes: "#ccc",
  hour: "#fff",
  tick: "#fff",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 8,
});
