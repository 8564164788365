import React, { useState } from "react";
import { Link } from "@reach/router";
import { PasswordForgotParams } from "../../types/types";
import { auth } from "../../firebase";

const PasswordForgot = ({ path }: PasswordForgotParams) => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };
  const sendResetEmail = async (event) => {
    event.preventDefault();
    await auth.sendPasswordResetEmail(email).catch(() => {
      setError("Error resetting password");
    });

    setEmailHasBeenSent(true);
    setTimeout(() => {
      setEmailHasBeenSent(false);
    }, 3000);
  };
  return (
    <div className="ocean h-screen">
      <h1 className="text-3xl mb-2 text-center text-white font-bold py-12">Reset your Password</h1>
      <div className="h-half  justify-around border mx-auto w-7/12 md:w-5/12  rounded py-8 px-4 md:px-8">
        <form action="">
          {emailHasBeenSent && (
            <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div className="py-2 bg-red-600 w-full text-white text-center rounded-lg mb-3">
              {error}
            </div>
          )}
          <div className="py-4">
            <label htmlFor="userEmail" className="text-white block">
              Email:
            </label>
            <input
              type="email"
              name="userEmail"
              id="userEmail"
              value={email}
              placeholder="Input your email"
              onChange={onChangeHandler}
              className="my-1 p-1 w-full py-2 px-3 rounded"
            />
          </div>
          <div className="py-6">
            <button className="my-green rounded-lg w-full py-2 text-white">
              Send me a reset link
            </button>
          </div>
        </form>
        <Link to="/" className="my-2 text-gray-400 hover:text-white text-center block py-6">
          &larr; Back to login
        </Link>
      </div>
    </div>
  );
};
export default PasswordForgot;
