import React from "react";
import TableDragSelect from "react-table-drag-select";

const TimePicker = ({ cells, setCells, timeDiff }) => {
  const timesPerHour = cells.length / 24;
  const am = cells.slice(0, cells.length / 2);
  const pm = cells.slice(cells.length / 2, cells.length);
  const modDiff = (12 + timeDiff!) % 12;
  var isAm = timeDiff > 0 ? true : false;

  return (
    <div>
      <TableDragSelect value={[am]}>
        <tr>
          {am!.map((i, index) => {
            if (index % timesPerHour === 0) {
              if ((index / timesPerHour + modDiff) % 12 === 0) {
                isAm = !isAm;
              }
              return (
                <td className="cell-disabled">
                  {(index / timesPerHour + modDiff) % 12 === 0
                    ? "12"
                    : (index / timesPerHour + modDiff) % 12}
                  {isAm ? "AM" : "PM"}
                </td>
              );
            }

            return <td className="cell-blank" />;
          })}
        </tr>
      </TableDragSelect>

      <TableDragSelect value={[am]} onChange={(ce) => setCells([...ce[0], ...pm])}>
        <tr>
          {am!.map(() => {
            return <td />;
          })}
        </tr>
      </TableDragSelect>
      <TableDragSelect value={[am]}>
        <tr>
          {am!.map((i, index) => {
            if (index % timesPerHour === 0) {
              if ((index / timesPerHour + modDiff) % 12 === 0) {
                isAm = !isAm;
              }
              return (
                <td className="cell-disabled">
                  {(index / timesPerHour + modDiff) % 12 === 0
                    ? "12"
                    : (index / timesPerHour + modDiff) % 12}
                  {isAm ? "AM" : "PM"}
                </td>
              );
            }

            return <td className="cell-blank" />;
          })}
        </tr>
      </TableDragSelect>
      <TableDragSelect value={[pm]} onChange={(ce) => setCells([...am, ...ce[0]])}>
        <tr>
          {pm!.map(() => {
            return <td />;
          })}
        </tr>
      </TableDragSelect>
    </div>
  );
};

export default TimePicker;
