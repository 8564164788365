import React, { useState } from "react";
import { Link } from "@reach/router";
import { LogInParams } from "../../types/types";
import { auth } from "../../firebase";
import Waves from "../../components/Waves";

const LogIn = ({ path }: LogInParams) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const signInWithEmailAndPasswordHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    email: string,
    password: string
  ) => {
    event.preventDefault();
    await auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="ocean h-screen">
      <h1 className="text-3xl mb-2 text-center text-white font-bold py-12">Log In</h1>
      <div className="h-half  justify-around border mx-auto md:w-5/12 w-7/12 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-2 bg-red-600 w-full text-white text-center rounded-lg mb-3">
            {error}
          </div>
        )}
        <form className=" w-full">
          <div className="pb-4">
            <label htmlFor="userEmail" className="block text-white">
              Email:
            </label>
            <input
              type="email"
              className="my-1 p-1 py-2 w-full px-3 rounded"
              name="userEmail"
              value={email}
              placeholder="Your Email"
              id="userEmail"
              onChange={(event) => onChangeHandler(event)}
            />
          </div>
          <div className="py-4">
            <label htmlFor="userPassword" className="block text-white">
              Password:
            </label>
            <input
              type="password"
              className="mt-1 mb-3 p-1 py-2 w-full px-3 rounded"
              name="userPassword"
              value={password}
              placeholder="Your Password"
              id="userPassword"
              onChange={(event) => onChangeHandler(event)}
            />
          </div>
          <div className="pt-4">
            <button
              className="my-green rounded-lg w-full py-2 text-white"
              onClick={(event) => {
                signInWithEmailAndPasswordHandler(event, email, password);
              }}>
              Log in
            </button>
          </div>
          <p className="text-center my-3">
            {/* Don't have an account?{" "}
          <Link to="signUp" className="text-blue-500 hover:text-blue-600">
            Sign up here
          </Link>{" "} */}
            <br />{" "}
            <Link to="passwordForgot" className="text-gray-400 hover:text-white">
              Forgot Password?
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};
export default LogIn;
