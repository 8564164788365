import React, { useEffect, useState } from "react";
import AnalogClock from "../../components/Clock";
import useWindowDimensions from "../../constants/utils";
import PieChart from "react-simple-pie-chart";

const theme = {
  background: "transparent",
  border: "transparent",
  center: "#000",
  seconds: "#fff",
  minutes: "#000",
  hour: "#000",
  tick: "#fff",
  smallTickWidth: 2,
  largeTickWidth: 4,
  secondHandWidth: 3,
  minuteHandWidth: 6,
  hourHandWidth: 10,
};

const colors = {
  lightGreen: "#88d9b0",
  darkGreen: "#06b37f",

  lightRed: "#fd979c",
  darkRed: "#f04660",
};

const CustomClock = ({ timeZone, initDate, times }) => {
  const [date, setDate] = useState(initDate);

  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000);
  }, []);

  const { height, width } = useWindowDimensions();

  const t = date.toLocaleTimeString("en-GB", {
    timeZone: timeZone,
    hour: "2-digit",
  });
  const pm = parseInt(t.slice(0, 2)) >= 12;
  const len = times.length;
  const outer = pm ? times.slice(0, len / 2).reverse() : times.slice(len / 2, len).reverse();
  const inner = pm ? times.slice(len / 2, len).reverse() : times.slice(0, len / 2).reverse();

  return (
    <div>
      <div className="mx-auto w-full py-8 ">
        <div className="flex w-full items-center justify-center relative">
          <h1 className="text-5xl font-bold text-center ">
            {date.toLocaleTimeString("en-US", {
              timeZone: timeZone,
            })}
          </h1>
        </div>
        <div className="flex w-full items-center justify-center ">
          <div className="pt-full relative w-full " />

          <div className="absolute md:w-5/12 w-11/12">
            <PieChart
              slices={outer.map((i, index) => {
                return { color: i ? colors.darkRed : colors.darkGreen, value: 360 / 12 / 5 };
              })}
            />
          </div>
          <div className="absolute md:w-2/5 w-10/12">
            <PieChart
              slices={inner.map((i, index) => {
                return { color: i ? colors.lightRed : colors.lightGreen, value: 360 / 12 / 5 };
              })}
            />
          </div>
          <div className="absolute">
            <AnalogClock
              // showSmallTicks={false}
              time={date.toLocaleTimeString("en-US", {
                timeZone: timeZone,
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
              width={width <= 768 ? width / 1.1 : width / 2.35}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomClock;
