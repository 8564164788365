"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});

var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

var _propTypes = require("prop-types");

var _propTypes2 = _interopRequireDefault(_propTypes);

var _AnalogClockLayout = require("./AnalogClockLayout");

var _AnalogClockLayout2 = _interopRequireDefault(_AnalogClockLayout);

var _styles = require("./styles");

var _styles2 = _interopRequireDefault(_styles);

var _util = require("./util");

var _themes = require("./themes");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " + typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: { value: subClass, enumerable: false, writable: true, configurable: true },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var AnalogClock = (function (_Component) {
  _inherits(AnalogClock, _Component);

  function AnalogClock(props) {
    _classCallCheck(this, AnalogClock);

    var _this = _possibleConstructorReturn(
      this,
      (AnalogClock.__proto__ || Object.getPrototypeOf(AnalogClock)).call(this)
    );

    _this.styles = (0, _util.cssTransform)(_styles2.default, props);
    return _this;
  }

  _createClass(AnalogClock, [
    {
      key: "componentWillReceiveProps",
      value: function componentDidUpdate(nextProps) {
        this.styles = (0, _util.cssTransform)(_styles2.default, nextProps);
      },
    },
    {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        clearInterval(this.interval);
      },
    },
    {
      key: "render",
      value: function render() {
        return _react2.default.createElement(
          _AnalogClockLayout2.default,
          _extends(
            {},
            { time: this.props.time },
            {
              styles: this.styles,
              showSmallTicks: this.props.showSmallTicks,
            }
          )
        );
      },
    },
  ]);

  return AnalogClock;
})(_react.Component);

exports.default = AnalogClock;

AnalogClock.propTypes = {
  theme: _propTypes2.default.shape({
    background: _propTypes2.default.string.isRequired,
    border: _propTypes2.default.string.isRequired,
    center: _propTypes2.default.string.isRequired,
    seconds: _propTypes2.default.string.isRequired,
    minutes: _propTypes2.default.string.isRequired,
    hour: _propTypes2.default.string.isRequired,
    tick: _propTypes2.default.string.isRequired,
  }),
  width: _propTypes2.default.number,
  time: _propTypes2.default.string,
  showSmallTicks: _propTypes2.default.bool,
};

AnalogClock.defaultProps = {
  theme: _themes.dark,
  width: 300,
  showSmallTicks: true,
};
