import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { auth, writeUserTimes } from "../../firebase";
import CustomClock from "../../components/CustomClock";
import TimePicker from "../../components/TimePicker";
import Waves from "../../components/Waves";

const Home = () => {
  const { user, otherUser, timeDiff } = useContext(UserContext) || {};
  const date = new Date();

  const [cells, setCells] = useState(user!.times);

  const init: boolean[] = [];
  const timesPerHour = 5;
  for (var i = 0; i < 24 * timesPerHour; i++) {
    init.push(false);
  }

  const otherCells = [
    ...otherUser!.times!.slice(timeDiff! * timesPerHour),
    ...otherUser!.times!.slice(0, timeDiff! * timesPerHour),
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-around md:flex-row md:items-start sand pt-6">
        <div className="w-full">
          <h1 className="text-6xl font-bold text-center">{user?.displayName}</h1>
          <CustomClock timeZone={user?.timeZone} initDate={date} times={cells} />
        </div>
        <div className="w-full">
          {otherUser && (
            <div>
              <h1 className="text-6xl font-bold text-center ">{otherUser?.displayName}</h1>
              <CustomClock timeZone={otherUser.timeZone} initDate={date} times={otherUser.times} />
            </div>
          )}
        </div>
      </div>

      <div>
        <Waves />
      </div>

      <div className="ocean pb-24">
        <div className="px-4">
          <div className="table-drag-select ">
            <h2 className="text-2xl font-semibold text-white pb-2">{user?.displayName}</h2>
            <TimePicker cells={cells} setCells={setCells} timeDiff={0} />
          </div>
          <div className="flex justify-center items-center w-full pt-4 pb-12">
            <button
              className="my-green w-5/12 py-3  mt-4 text-white rounded-lg  "
              onClick={() => {
                writeUserTimes(user!.uid, cells);
              }}>
              Save
            </button>
          </div>

          {/*  split  */}

          {otherUser && (
            <div className="table-drag-select">
              <h2 className="text-2xl font-semibold text-white pb-2">{otherUser?.displayName}</h2>
              <TimePicker cells={otherCells} setCells={() => {}} timeDiff={timeDiff} />
            </div>
          )}
        </div>
        <div className="flex justify-center items-center w-full pt-10">
          <button
            className="w-5/12 py-3 my-red mt-4 text-white rounded-lg "
            onClick={() => {
              auth.signOut();
            }}>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};
export default Home;
