import React, { createContext, useState, useEffect } from "react";
import { auth, generateUserDocument, getOtherUser } from "../firebase";
import { UserContextParams, UserParams } from "../types/types";

export const UserContext = createContext<UserContextParams>({
  user: null,
  otherUser: null,
  timeDiff: 0,
});

const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<UserParams>(null);
  const [otherUser, setOtherUser] = useState<UserParams>(null);
  const [timeDiff, setTimeDiff] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      const document = await generateUserDocument(userAuth);
      setUser(document);
    });
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const otherUid =
        user?.uid === "1zFK0uZ1UtbuUyorxjLQl6Vt3e73"
          ? "8qU2y4PDkIPvTPxB8MV7E6Gk90F2"
          : "1zFK0uZ1UtbuUyorxjLQl6Vt3e73";
      setTimeDiff(user?.uid === "1zFK0uZ1UtbuUyorxjLQl6Vt3e73" ? -8 : 8);
      const doc = await getOtherUser(otherUid);
      setOtherUser(doc);
    };

    if (user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (otherUser) {
      setTimeDiff(user?.uid === "1zFK0uZ1UtbuUyorxjLQl6Vt3e73" ? -8 : 8);
    }
  }, [user, otherUser]);

  return (
    <UserContext.Provider value={{ user, otherUser, timeDiff }}>{children}</UserContext.Provider>
  );
};
export default UserProvider;
